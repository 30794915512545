// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/pencil.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/assets/trash-bin.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-size: 18px;

    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.create-button{
    margin: 1rem 0;

    width: 100%;
    height: 5rem;

    font-size: 1rem;

    color: rgba(160, 160, 160, 0.897);;

    border: 2px solid rgba(31, 29, 29, 0.884);
    border-radius: 5px;
    background-color: rgba(56, 56, 56, 0.712);

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .1s ease-in-out;

    border: 2px solid #87d9d157;

}

input:active {
    outline: none;
    color: rgba(182, 182, 182, 0.897);
    background-color: rgba(56, 56, 56, 0.712);

    box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px;
    transition: .1s ease-in-out;
}

.edit-btn {
    background-position: center;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
}

.delete-btn {
    background-position: center;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/ui/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;IAEf,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,cAAc;;IAEd,WAAW;IACX,YAAY;;IAEZ,eAAe;;IAEf,iCAAiC;;IAEjC,yCAAyC;IACzC,kBAAkB;IAClB,yCAAyC;;IAEzC,2CAA2C;IAC3C,2BAA2B;;IAE3B,2BAA2B;;AAE/B;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,yCAAyC;;IAEzC,4CAA4C;IAC5C,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;IAC3B,yDAA0D;IAC1D,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,yDAA6D;IAC7D,4BAA4B;AAChC","sourcesContent":["* {\n    font-size: 18px;\n\n    font-family: 'Lato', sans-serif;\n    font-weight: 400;\n}\n\n.create-button{\n    margin: 1rem 0;\n\n    width: 100%;\n    height: 5rem;\n\n    font-size: 1rem;\n\n    color: rgba(160, 160, 160, 0.897);;\n\n    border: 2px solid rgba(31, 29, 29, 0.884);\n    border-radius: 5px;\n    background-color: rgba(56, 56, 56, 0.712);\n\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n    transition: .1s ease-in-out;\n\n    border: 2px solid #87d9d157;\n\n}\n\ninput:active {\n    outline: none;\n    color: rgba(182, 182, 182, 0.897);\n    background-color: rgba(56, 56, 56, 0.712);\n\n    box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px;\n    transition: .1s ease-in-out;\n}\n\n.edit-btn {\n    background-position: center;\n    background-image: url(\"../../../public/assets/pencil.png\");\n    background-repeat: no-repeat;\n}\n\n.delete-btn {\n    background-position: center;\n    background-image: url(\"../../../public/assets/trash-bin.png\");\n    background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
