// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.movement{
    width: 100%;

    border-radius: 5px;
    color: rgba(182, 182, 182, 0.897);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid rgba(31, 29, 29, 0.884);
    justify-content: space-between;

    align-items: center;

    font-size: 1rem;


}

.description {
    font-size: 1.1rem;
}

.username{
    font-size: .9rem;
}

.value{
    font-size: 1rem;
}

.date{
    font-size: .8rem;
}

.description, .username, .value, .date{
    margin: 0;
    padding: 0;
}

.bottom-menu{
    justify-content: end;
}

.buttons{
    display: flex;
    gap: .5rem;
}

.buttons > input {
    padding: 0rem 1rem;
    max-height: 2rem!important;

}

.show{
    display: flex!important;
}

.hide{
    display: none!important;
}`, "",{"version":3,"sources":["webpack://./src/components/Movement/Movement.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX,kBAAkB;IAClB,iCAAiC;IACjC,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,yCAAyC;IACzC,8BAA8B;;IAE9B,mBAAmB;;IAEnB,eAAe;;;AAGnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;;AAE9B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".movement{\n    width: 100%;\n\n    border-radius: 5px;\n    color: rgba(182, 182, 182, 0.897);\n    display: flex;\n    flex-direction: column;\n    padding: 1rem;\n    border: 1px solid rgba(31, 29, 29, 0.884);\n    justify-content: space-between;\n\n    align-items: center;\n\n    font-size: 1rem;\n\n\n}\n\n.description {\n    font-size: 1.1rem;\n}\n\n.username{\n    font-size: .9rem;\n}\n\n.value{\n    font-size: 1rem;\n}\n\n.date{\n    font-size: .8rem;\n}\n\n.description, .username, .value, .date{\n    margin: 0;\n    padding: 0;\n}\n\n.bottom-menu{\n    justify-content: end;\n}\n\n.buttons{\n    display: flex;\n    gap: .5rem;\n}\n\n.buttons > input {\n    padding: 0rem 1rem;\n    max-height: 2rem!important;\n\n}\n\n.show{\n    display: flex!important;\n}\n\n.hide{\n    display: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
