// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input{
    width: 100%;
    height: 4rem;
    padding: 0 1rem;

    font-size: 1rem;

    border: 2px solid rgba(31, 29, 29, 0.884);
    border-radius: .4rem;
    background-color: rgba(56, 56, 56, 0.712);

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .5s ease-in-out;

    text-align: center;
    color: var(--neutral-grey);
}

label {
    padding: .5rem 0;
    color: rgba(160, 160, 160, 0.897);
    font-size: 1rem;
}

input:focus-visible {
    outline: none;
    color: rgba(182, 182, 182, 0.897);
    background-color: rgba(56, 56, 56, 0.712);

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: .5s ease-in-out;
}

.option{
    width: 98%;

    border-bottom: 1px solid rgba(31, 29, 29, 0.884);
    border-radius: 5px;

    text-align: center;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;

    background-color: rgba(56, 56, 56, 0.712);
    color: rgba(182, 182, 182, 0.897);
}

.option:hover{
    opacity: .2;
}

.hidden{
    display: none!important;
}`, "",{"version":3,"sources":["webpack://./src/ui/Input/Input.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;;IAEf,eAAe;;IAEf,yCAAyC;IACzC,oBAAoB;IACpB,yCAAyC;;IAEzC,2CAA2C;IAC3C,2BAA2B;;IAE3B,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,yCAAyC;;IAEzC,4CAA4C;IAC5C,2BAA2B;AAC/B;;AAEA;IACI,UAAU;;IAEV,gDAAgD;IAChD,kBAAkB;;IAElB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,yCAAyC;IACzC,iCAAiC;AACrC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["input{\n    width: 100%;\n    height: 4rem;\n    padding: 0 1rem;\n\n    font-size: 1rem;\n\n    border: 2px solid rgba(31, 29, 29, 0.884);\n    border-radius: .4rem;\n    background-color: rgba(56, 56, 56, 0.712);\n\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n    transition: .5s ease-in-out;\n\n    text-align: center;\n    color: var(--neutral-grey);\n}\n\nlabel {\n    padding: .5rem 0;\n    color: rgba(160, 160, 160, 0.897);\n    font-size: 1rem;\n}\n\ninput:focus-visible {\n    outline: none;\n    color: rgba(182, 182, 182, 0.897);\n    background-color: rgba(56, 56, 56, 0.712);\n\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    transition: .5s ease-in-out;\n}\n\n.option{\n    width: 98%;\n\n    border-bottom: 1px solid rgba(31, 29, 29, 0.884);\n    border-radius: 5px;\n\n    text-align: center;\n    display: flex;\n    padding: 1rem;\n    justify-content: center;\n    align-items: center;\n\n    background-color: rgba(56, 56, 56, 0.712);\n    color: rgba(182, 182, 182, 0.897);\n}\n\n.option:hover{\n    opacity: .2;\n}\n\n.hidden{\n    display: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
