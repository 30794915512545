// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row{
    display: flex;
    width: 100%;
    padding: 0 1rem;

    gap:1rem;
}

.column{
    display: flex;
    flex-direction: column;
}

.row-center{
    justify-content: center;
}

.column-center{
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Insert/Insert.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,eAAe;;IAEf,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".row{\n    display: flex;\n    width: 100%;\n    padding: 0 1rem;\n\n    gap:1rem;\n}\n\n.column{\n    display: flex;\n    flex-direction: column;\n}\n\n.row-center{\n    justify-content: center;\n}\n\n.column-center{\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
