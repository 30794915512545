// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --dark-grey: #292929;
    --neutral-grey: #6b6b6bb6;
    --light-grey: #b6b6b6e5;

    
}

* {
    font-size: 18px;

    font-family: 'Lato', sans-serif;
    font-weight: 400;
    background-color: rgb(41, 41, 41);

    box-sizing: border-box;
}

.container {
    background-color: #292929;

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 1rem;

    padding: 2rem 0;
}

.g-3{
    gap: 3rem;
}

.row{
    display: flex;
    width: 100%;
    padding: 0 1rem;

    gap:1rem;
}

.column{
    display: flex;
    flex-direction: column;
}

.row-center{
    justify-content: center;
}

.column-center{
    align-items: center;
}



/* ########################################### */

.p-tb-2{
    padding: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Homepage/Homepage.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,uBAAuB;;;AAG3B;;AAEA;IACI,eAAe;;IAEf,+BAA+B;IAC/B,gBAAgB;IAChB,iCAAiC;;IAEjC,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;;IAEzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,SAAS;;IAET,eAAe;AACnB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,eAAe;;IAEf,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;;;AAIA,gDAAgD;;AAEhD;IACI,eAAe;AACnB","sourcesContent":[":root{\n    --dark-grey: #292929;\n    --neutral-grey: #6b6b6bb6;\n    --light-grey: #b6b6b6e5;\n\n    \n}\n\n* {\n    font-size: 18px;\n\n    font-family: 'Lato', sans-serif;\n    font-weight: 400;\n    background-color: rgb(41, 41, 41);\n\n    box-sizing: border-box;\n}\n\n.container {\n    background-color: #292929;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    gap: 1rem;\n\n    padding: 2rem 0;\n}\n\n.g-3{\n    gap: 3rem;\n}\n\n.row{\n    display: flex;\n    width: 100%;\n    padding: 0 1rem;\n\n    gap:1rem;\n}\n\n.column{\n    display: flex;\n    flex-direction: column;\n}\n\n.row-center{\n    justify-content: center;\n}\n\n.column-center{\n    align-items: center;\n}\n\n\n\n/* ########################################### */\n\n.p-tb-2{\n    padding: 2rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
