// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    color: var(--light-grey);

    padding: 1rem;
}

.card > h1{
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
}

.card > label{
    padding: 0;
    margin: 0;

    font-size: .7rem;
    color: var(--neutral-grey);
}

.personal-debt{
    color: rgba(175, 44, 44, 0.719);
}

.personal-debt-pos{
    color: rgba(57, 182, 78, 0.719);
}

.personal-debt{
    margin: 0;
    padding: 0;
    
    font-size: .8rem;
}`, "",{"version":3,"sources":["webpack://./src/ui/Card/Card.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2CAA2C;IAC3C,aAAa;IACb,sBAAsB;;IAEtB,uBAAuB;IACvB,mBAAmB;;IAEnB,wBAAwB;;IAExB,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,SAAS;;IAET,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,SAAS;IACT,UAAU;;IAEV,gBAAgB;AACpB","sourcesContent":[".card{\n    width: 100%;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    \n    display: flex;\n    flex-direction: column;\n\n    justify-content: center;\n    align-items: center;\n\n    color: var(--light-grey);\n\n    padding: 1rem;\n}\n\n.card > h1{\n    margin: 0;\n    padding: 0;\n    font-size: 1.4rem;\n}\n\n.card > label{\n    padding: 0;\n    margin: 0;\n\n    font-size: .7rem;\n    color: var(--neutral-grey);\n}\n\n.personal-debt{\n    color: rgba(175, 44, 44, 0.719);\n}\n\n.personal-debt-pos{\n    color: rgba(57, 182, 78, 0.719);\n}\n\n.personal-debt{\n    margin: 0;\n    padding: 0;\n    \n    font-size: .8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
