// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total{
    display: flex;
    color: var(--light-grey);
}

.total-month{
    padding: 0!important;
    margin: 0;
    font-size: .8rem;

    color: var(--neutral-grey);
}

.total-monthly{
    font-size: 2rem;
    padding: 0;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/MonthTotal/MonthTotal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,oBAAoB;IACpB,SAAS;IACT,gBAAgB;;IAEhB,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,UAAU;IACV,SAAS;AACb","sourcesContent":[".total{\n    display: flex;\n    color: var(--light-grey);\n}\n\n.total-month{\n    padding: 0!important;\n    margin: 0;\n    font-size: .8rem;\n\n    color: var(--neutral-grey);\n}\n\n.total-monthly{\n    font-size: 2rem;\n    padding: 0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
