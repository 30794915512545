import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Layout } from './components/Layout/Layout';
import { AuthProvider } from './auth/AuthProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <AuthProvider>
      <Layout>
        <></>
      </Layout>
    </AuthProvider>
);
